<template>
  <main :class="[navopen? 'h-auto backdrop-blur-xl' : 'h-16 backdrop-blur-xl', scrollPosition > 90 ? 'bg-gray3' : 'bg-transparent', isScrollup ? 'fixed' : 'absolute top-0 left-0']" class="z-[99] w-full lg:h-24 m-auto flex justify-center items-start lg:items-center pt-6 lg:pt-0 pb-12 lg:pb-2 overflow-hidden transition-all duration-500">
    <div class="px-4 md:px-16 lg:px-0 max-w-desktop w-full flex flex-col lg:flex-row justify-between items-start lg:items-center">
      <a href="#">
      <img
        :src="logo"
        class="h-12 w-28 md:h-auto md:w-auto -mt-3 lg:mt-0"
      />
      </a>
      <div class="w-2/4 mt-10 lg:mt-0 text-base lg:text-base text-custom flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 lg:gap-0">
        <a href="#">About us</a>
        <a href="#services">Services</a>
        <a href="#faq">FAQ</a>
        <a href="https://reback.atlassian.net/servicedesk/customer/portal/3" target="_blank">Support</a>
      </div>
      <a href="https://app.reback.finance" target="_blank"><button class="mt-7 lg:mt-0 text-sm lg:text-base py-3 px-5 md:py-4 md:px-8 bg-primary text-gray1 rounded-lg">Sign in</button></a>
    </div>
    <div @click="navopen = !navopen" class="pr-4 md:pr-16 lg:hidden h-5 flex flex-col justify-between">
      <span class="w-8 h-[1.8px] bg-dark-shade relative block rounded-xl"></span>
      <span class="w-8 h-[1.8px] bg-dark-shade relative block rounded-xl"></span>
      <span class="w-8 h-[1.8px] bg-dark-shade relative block rounded-xl"></span>
    </div>
  </main>
</template>

<script>
// import { logo } from '@/assets'

  export default {
    name: 'Navbar-component',
    components: {
    },
    data() {
      return {
        logo: require('@/assets/images/logo.svg'),
        navopen: false,
        scrollPosition: 0,
        isScrollup: false,
        lastScroll: 0
    }
  },
  methods: {
    getScroll() {
      this.scrollPosition = window.pageYOffset;
      if(window.pageYOffset > this.lastScroll) {
        this.isScrollup = false
      } else {
        this.isScrollup = true
      }
      console.log(window.pageYOffset, this.lastScroll, this.isScrollup)
      this.lastScroll = window.pageYOffset;
    }
  },
  mounted () {
    window.addEventListener('scroll', this.getScroll);

  }
}
</script>

<style scoped></style>
