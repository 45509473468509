<template>
  <main class="hidden md:inline-block fixed top-[54%] sm:top-[40%] -right-[28vw] sm:-right-[15vw] md:-right-[12.3vw] lg:-right-[9.1vw] xl:-right-[6.9vw] 2xl:-right-[5.9vw]">
    <div class="-rotate-90">
      <a href="https://app.reback.finance/signup" target="_blank"><button class="text-white px-12 py-4 rounded-t-2xl bg-grey1 font-[800] hover:bg-primary">Create a free account</button></a>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'Side-button'
  }
</script>